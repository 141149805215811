// @flow

import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';

type Props = {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          id: string,
          fields: {
            slug: string,
          },
          frontmatter: {
            title: string,
          },
        },
      }>,
    },
  },
};

const IndexPage = ({ data }: Props) => {
  const { edges: reviews } = data.allMarkdownRemark;

  return (
    <Layout>
      <header>
        <h1>All Reviews</h1>
      </header>
      <div>
        {reviews.map(({ node: review }) => (
          <Link to={review.fields.slug} key={review.id}>
            {review.frontmatter.title}
          </Link>
        ))}
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: { fields: { type: { eq: "review" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 170)
          id
          fields {
            date
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
